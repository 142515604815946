<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getVoidBillReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="voidBillTitle" class="col-md-10 col-sm-9 col-8 font-weight-normal">
              {{ $t('report.txtvoidbill') }}
            </h2>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton id="voidBillExportButton" v-on:click="exportVoidBillReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <DataTable
                id="voidBillDataTable"
                style="white-space: nowrap;"
                :items="items()"
                :fields="fields"
                :loading="loading"
                hover
                border
                clickableRows
                @row-clicked="toggleDetails"
              >
                <template #details="{item,index}" @click="toggleDetails(item)" >
                 <div :id="'voidBillDataTableDetail' + index">
                  <CCollapse :show="details.includes(item)">
                    <CCardBody>
                      <hr />
                      <p class="font-weight-normal">
                        {{ $t('voidBillDetail') }}
                      </p>
                      <DataTable
                        :items="itemsDetail(index)"
                        :fields="fieldsDetail"
                        :loading="loading"
                        hover
                        border
                      >
                      </DataTable>
                    </CCardBody>
                  </CCollapse>
                  </div>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getVoidBillReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import Pagination from '@/containers/Pagination'
import permis from '@/util/permission'

export default {
  components: {
    DataTable,
    Pagination,
  },
  data() {
    return {
      data: [],
      details: [],
      loadingButton: true,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'dateTime',
          label: this.$i18n.t('date'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'bills',
          label: this.$i18n.t('billAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vat'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('grandTotal'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    
    fieldsDetail() {
      return [
        { key: 'receiptNumber', label: this.$i18n.t('receiptNumber'), _classes: ' text-dark font-weight-normal' },
        { key: 'PLUCode', label: this.$i18n.t('pluCode'), _classes: 'text-dark font-weight-normal' },
        { key: 'name', label: this.$i18n.t('pluCode'), _classes: 'text-dark font-weight-normal' },
        {
          key: 'quantitySKU',
          label: this.$i18n.t('quantity'),
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'price',
          label: this.$i18n.t('priceUnit'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'totalAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right text-dark font-weight-normal ',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('totalAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'updated_by',
          label: this.$i18n.t('cancelBy'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'updated_date',
          label: this.$i18n.t('cancelDate'),
          _classes: 'text-left text-dark font-weight-normal ',
        },
      ]
    },
  },
  created() {
    this.getVoidBillReport()
  },
  methods: {
    ...util,
    items() {
      let data = this.data
      let info = []
      let vatAmount = ''
      let netAmount = ''
      for (let i = 0; i < data.length; i++) {
        if (data[i].discountAmount === undefined) {
          discountAmount = '-'
        } else {
          discountAmount = util.convertCurrency(data[i].discountAmount)
        }

        if (data[i].vatAmount === undefined) {
          vatAmount = '-'
        } else {
          vatAmount = util.convertCurrency(data[i].vatAmount)
        }
        let discountAmount = 0
        let discountItem = 0
        let discount = 0

        if (data[i].discountAmount !== undefined) {
          discountAmount = data[i].discountAmount
        }
        if (data[i].discountItem !== undefined) {
          discountItem = data[i].discountItem
        }

        discount = discountAmount + discountItem

        if (data[i].netAmount === undefined) {
          netAmount = '-'
        } else {
          netAmount = util.convertCurrency(data[i].netAmount)
        }
        info.push({
          dateTime: moment(String(data[i].date)).format('DD/MM/YYYY'),
          bills: data[i].bills,
          netAmount: netAmount,
          discountAmount: util.convertCurrency(discount),
          vatAmount: util.convertCurrency(vatAmount),
          grandTotal: util.convertCurrency(data[i].grandTotal),
        })
      }
      return info
    },
    itemsDetail(index) {
      let data = this.data
      let info = []
      let name = ''
      let updated_by = ''
      let PLUCode = ''
      let discount = 0
      let discountBillPerQty = 0
      let total = 0
      let discountAmount = 0

      for (let i = 0; i < data[index].list.length; i++) {
        for (let j = 0; j < data[index].list[i].receiptItems.length; j++) {
          if (data[index].list[i].receiptItems[j].productPLU === undefined) {
            PLUCode = '-'
            name = '-'
          } else {
            PLUCode = data[index].list[i].receiptItems[j].productPLU.PLUCode || '-'
            name = data[index].list[i].receiptItems[j].productPLU.name
          }
          updated_by = data[index].list[i]?.updated_by?.username || '-'

          if (data[index].list[i].receiptItems[j].discountBillPerQty !== null) {
            discountBillPerQty =
              data[index].list[i].receiptItems[j].discountBillPerQty
          }
          if (data[index].list[i].receiptItems[j].discountAmount !== null) {
            discountAmount = data[index].list[i].receiptItems[j].discountAmount
          }
          discount = discountBillPerQty + discountAmount
          total =
            data[index].list[i].receiptItems[j].netAmount -
            data[index].list[i].receiptItems[j].discountBillPerQty

          const updated_date = moment(
            String(data[index].list[i].updated_at)
          ).format('DD/MM/YYYY HH:mm:ss')
          info.push({
            receiptNumber: data[index].list[i].receiptNumber,
            PLUCode: PLUCode,
            name: name,
            quantitySKU: data[index].list[i].receiptItems[j].quantitySKU,
            price: util.convertCurrency(
              data[index].list[i].receiptItems[j].price
            ),
            netAmount: util.convertCurrency(total),
            discountAmount: util.convertCurrency(discount),
            totalAmount: util.convertCurrency(
              data[index].list[i].receiptItems[j].totalAmount
            ),
            updated_by: updated_by,
            updated_date: updated_date,
          })
        }
      }

      return info
    },
    getVoidBillReport() {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        limit: 50,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      axios({
        url: '/receipt/v1.0/getvoid/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportVoidBillReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/receipt/v1.0/getvoid/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานยกเลิกการขาย.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleDetails(index) {
      const position = this.details.indexOf(index)
      position !== -1
        ? this.details.splice(position, 1)
        : this.details.push(index)
    },
  },
}
</script>
